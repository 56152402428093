@config "../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .border-arrow:before {
    content: "";
    width: 10rem;
    height: 0;
    border-style: solid;
    border-width: 6rem 7rem 0 7rem;
    border-color: theme('colors.steel.teaser') transparent transparent transparent;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 1rem;
  }

  @screen md {
    .border-arrow:before {
      width: 15rem;
      border-width: 10rem 11rem 0 11rem;
      left: 2rem;
    }
  }

  .border-arrow-crimson:before {
    border-color: theme('colors.crimson') transparent transparent transparent;
  }

  .border-arrow-rio:before {
    border-color: theme('colors.rio') transparent transparent transparent;
  }

  .border-arrow-cello:before {
    border-color: theme('colors.cello.default') transparent transparent transparent;
  }

  .text-shadow {
    text-shadow: 0px 0px 0 rgba(0, 0, 0, 0.35);
  }

  .gallery-item:hover .caption {
    @apply opacity-75;
  }

  .gallery-item:hover .caption:hover {
    @apply opacity-100;
    @apply bg-gray-100;
    @apply bg-opacity-100;
  }

  .rich-text-default p {
    margin-bottom: 1rem;
  }

  .more-link:hover .hover\:bg-cello-light {
    background-color: theme('colors.cello.light');
  }

  @screen md {

    .h-50-mt4 {
      height: calc(50% - theme('spacing.4'));
    }

    .h-full-mt4 {
      height: calc(100% + theme('spacing.4'));
    }
  }

  .top-gallery-thumb {
    top: -3.4rem;
  }

  .bottom-gallery-thumb {
    bottom: -1.925rem;
  }

  .min-h-24 {
    min-height: theme('spacing.24');
  }

  .swiper-slide-thumb-active {
    border-color: theme('colors.cello.light');
  }

  .swiper-slide-thumb-active.swiper-slide {
    opacity: 1;
  }



  .ts-wrapper .ts-control {
    @apply text-base bg-gray-200 appearance-none border-2 border-gray-200 rounded focus:outline-none focus:bg-white hover:border-lochinvar-light transition-colors duration-200 focus:border-gray-500 px-4 py-2 h-12 leading-7;
    print-color-adjust: exact;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
  }

  .ts-wrapper .ts-control > input,
  .ts-wrapper .ts-dropdown {
    @apply text-base text-black leading-7
  }

  .ts-wrapper .ts-dropdown-content {
    max-height: 50vh;
  }

  .ts-wrapper.plugin-remove_button .item {
    @apply rounded-md
  }

  .ts-wrapper.multi .ts-control>div {
    @apply p-0 m-0 pl-1
  }

  .ts-wrapper.full .ts-control {
    @apply bg-gray-200
  }

  .ts-wrapper.plugin-remove_button .item .remove {
    @apply border-none text-lg leading-none py-1 rounded-r-lg
  }

  .ts-dropdown {
    @apply rounded-md text-base border border-solid border-t border-gray-300
  }

  .ts-dropdown [data-selectable].option:first-child {
    @apply rounded-t-md
  }

  .ts-dropdown [data-selectable].option:last-child {
    @apply rounded-b-md
  }

  .ts-dropdown .create:hover, .ts-dropdown .option:hover, .ts-dropdown .active {
    @apply bg-lochinvar-lighter text-lochinvar-default
  }


}

[x-cloak] {
  display: none;
}

@screen md {

  .dont-break-inside-paragraf p {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 1rem;
  }
}
